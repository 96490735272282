import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { ApiKeysComponent } from "./api-keys.component";
import { ApiKeyFormComponent } from "./api-key-form/api-key-form.component";
import { ApiKeyListComponent } from "./api-key-list/api-key-list.component";

// Routes
import { ApiKeysRouting } from "./api-keys.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, ApiKeysRouting],
    declarations: [ApiKeysComponent, ApiKeyFormComponent, ApiKeyListComponent]
})
export class ApiKeysModule {}
