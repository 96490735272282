import { ComponentRef } from "@angular/core";
import { KeyMap, ApiKey } from "../../../models/shared";
import { FunctionRef, ZxShowCopyLinksComponent } from "./zx-show-copy-links.component";

export const assignShowCopyLinksInputsFactory =
    <T = ApiKey>(toggleValueRef: FunctionRef<T>, copyStringRef: FunctionRef<T>) =>
    (ComponentRef: ComponentRef<ZxShowCopyLinksComponent>, row: KeyMap<T>): void => {
        const comp = ComponentRef.instance;
        comp.toggleValueRef = toggleValueRef;
        comp.copyStringRef = copyStringRef;
        comp.row = row;
    };
