<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'API_KEYS' | translate }}">{{ "API_KEYS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="api-keys-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'KEYS' | translate }}">{{ "KEYS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="api-keys-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'DOCUMENTATION' | translate }}">{{ "DOCUMENTATION" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <p>Download the API Swagger documentation <a href="/api/v2" download>here</a></p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
