import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { ApiKeysService } from "../api-keys.service";
import { ApiKey } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class ApiKeyListResolverService implements Resolve<ApiKey[]> {
    constructor(private tps: ApiKeysService) {}

    resolve(): Observable<ApiKey[]> | Observable<never> {
        return new Observable((observe: Subscriber<ApiKey[]>) => {
            this.tps.refreshApiKeys().subscribe((apiKeys: ApiKey[]) => {
                observe.next(apiKeys);
                observe.complete();
            });
        });
    }
}
