import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { ApiKey, APIResponse } from "../../../models/shared";

import { AuthService } from "src/app/services/auth.service";
import _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class ApiKeysService {
    apiKeys: Observable<ApiKey[]>;
    private apiKeys$: ReplaySubject<ApiKey[]>;
    private dataStore: {
        apiKeys: ApiKey[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            apiKeys: []
        };
        this.apiKeys$ = new ReplaySubject(1) as ReplaySubject<ApiKey[]>;
        this.apiKeys = this.apiKeys$.asObservable();
    }

    private prepApiKey(apiKey: ApiKey) {
        return apiKey;
    }

    private updateStore(apiKey: ApiKey, merge: boolean): void {
        this.prepApiKey(apiKey);

        const currentIndex = this.dataStore.apiKeys.findIndex(g => g.id === apiKey.id);
        if (currentIndex === -1) {
            this.dataStore.apiKeys.push(apiKey);
            return;
        } else if (merge) {
            const current = this.dataStore.apiKeys[currentIndex];

            Object.assign(current, apiKey);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.apiKeys[currentIndex] = apiKey;
        }
    }

    refreshApiKeys(): Observable<ApiKey[]> {
        const apiKeys$ = this.http
            .get<APIResponse<ApiKey[]>>(Constants.apiUrl + Constants.apiUrls.apiKeys)
            .pipe(share());

        apiKeys$.subscribe(
            data => {
                const apiKeys: ApiKey[] = data.result;

                this.dataStore.apiKeys.forEach((existing, existingIndex) => {
                    const newIndex = apiKeys.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.apiKeys.splice(existingIndex, 1);
                });

                apiKeys.forEach(tp => this.updateStore(tp, true));

                this.apiKeys$.next(_.cloneDeep(this.dataStore).apiKeys);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return apiKeys$.pipe(map(r => r.result));
    }

    async addApiKey(model: string | ApiKey): Promise<ApiKey | false> {
        try {
            const result = await this.http
                .post<APIResponse<ApiKey>>(Constants.apiUrl + Constants.apiUrls.apiKeys, model)
                .toPromise();

            const apiKey: ApiKey = result.result;
            this.updateStore(apiKey, false);

            this.apiKeys$.next(Object.assign({}, this.dataStore).apiKeys);
            return apiKey;
        } catch (error) {
            return false;
        }
    }

    async deleteApiKey(apiKey: ApiKey | string): Promise<boolean> {
        try {
            const result = await this.http
                .delete<APIResponse<string>>(
                    Constants.apiUrl +
                        Constants.apiUrls.apiKeys +
                        "/" +
                        `${typeof apiKey === "string" ? apiKey : apiKey.id}`
                )
                .toPromise();

            const deletedId: string = result.result;

            const index = this.dataStore.apiKeys.findIndex(ak => ak.id === deletedId);
            if (index !== -1) this.dataStore.apiKeys.splice(index, 1);

            this.apiKeys$.next(Object.assign({}, this.dataStore).apiKeys);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateApiKey(apiKey: ApiKey | string, model: ApiKey | string): Promise<ApiKey | false> {
        try {
            const result = await this.http
                .put<APIResponse<ApiKey>>(
                    Constants.apiUrl +
                        Constants.apiUrls.apiKeys +
                        "/" +
                        `${typeof apiKey === "string" ? apiKey : apiKey.id}`,
                    typeof model === "string" ? { name: model } : model
                )
                .toPromise();

            const updatedApiKey: ApiKey = result.result;
            this.updateStore(updatedApiKey, true);

            this.apiKeys$.next(Object.assign({}, this.dataStore).apiKeys);

            return updatedApiKey;
        } catch (error) {
            return false;
        }
    }

    async getApiKeyValue(apiKey: ApiKey | string): Promise<ApiKey | false> {
        try {
            const result = await this.http
                .get<APIResponse<ApiKey>>(
                    Constants.apiUrl +
                        Constants.apiUrls.apiKeys +
                        "/" +
                        `${typeof apiKey === "string" ? apiKey : apiKey.id}`
                )
                .toPromise();

            const fullApiKey: ApiKey = result.result;
            return fullApiKey;
        } catch (error) {
            return false;
        }
    }
}
